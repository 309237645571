import React, { useState, useEffect } from "react";
import Select from "react-select";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_pass_payout.css";
import { updatePassPayout } from "../../Api/apiUrls";
import { Fade } from "react-bootstrap";

function Update_PassPayout({
  passPayoutId,
  passPayoutStatus,
  instructorPayment,
  venueProviderPayment,
  brandRepPayment,
  instructorPaymentDone,
  venueProviderPaymentDone,
  brandRepPaymentDone,
  remark,
  onClose
}) {
  const [newPassPayoutStatus, setNewPassPayoutStatus] = useState(null);
  const [newInstructorPaymentDone, setNewInstructorPaymentDone] = useState(instructorPaymentDone);
  const [newVenueProviderPaymentDone, setNewVenueProviderPaymentDone] = useState(venueProviderPaymentDone);
  const [newBrandRepPaymentDone, setNewBrandRepPaymentDone] = useState(brandRepPaymentDone);
  const [newRemark, setNewRemark] = useState(remark);
  const [isUpdatingPassPayout, setIsUpdatingPassPayout] = useState(false);

  useEffect(() => {
    const selectedStatus = statusOptions.find(option => option.value === passPayoutStatus);
    setNewPassPayoutStatus(selectedStatus);
  }, [passPayoutStatus]);

  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];

  const handleStatusChange = (selectedOption) => {
    setNewPassPayoutStatus(selectedOption);
  };

  const handleUpdate = () => {
    setIsUpdatingPassPayout(true);
    const formData = new FormData();
    formData.append("pass_management_schedule_booked_id", passPayoutId);
    formData.append("status", newPassPayoutStatus.label);
    formData.append("instructor_pay_done", newInstructorPaymentDone);
    formData.append("vp_pay_done", newVenueProviderPaymentDone);
    formData.append("br_pay_done", newBrandRepPaymentDone);
    formData.append("remark", newRemark);

    fetch(updatePassPayout, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated successfully!");
          window.location.reload();
        } else {
          console.error("Error updating payout:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating payout:", error);
      })
      .finally(() => {
        setIsUpdatingPassPayout(false);
      });
  };

  return (
    <div className="popup-background">
      <div className="popup">
      <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Instructor Payout</b>
              </h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => onClose(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
            <form>
              <div className="form-group">
                <label>Instructor Payment</label>
                <p>{instructorPayment}</p>
              </div>
              <div className="form-group">
                <label>Instructor Payment Done</label>
                <input
                  type="number"
                  className="form-control"
                  value={newInstructorPaymentDone}
                  onChange={(e) => setNewInstructorPaymentDone(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Venue Provider Payment</label>
                <p>{venueProviderPayment}</p>
              </div>
              <div className="form-group">
                <label>Venue Provider Payment Done</label>
                <input
                  type="number"
                  className="form-control"
                  value={newVenueProviderPaymentDone}
                  onChange={(e) => setNewVenueProviderPaymentDone(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Brand Representative Payment</label>
                <p>{brandRepPayment}</p>
              </div>
              <div className="form-group">
                <label>Brand Representative Payment Done</label>
                <input
                  type="number"
                  className="form-control"
                  value={newBrandRepPaymentDone}
                  onChange={(e) => setNewBrandRepPaymentDone(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Remark</label>
                <textarea
                  className="form-control remark-box"
                  value={newRemark}
                  onChange={(e) => setNewRemark(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Payout Status</label>
                <Select
                  options={statusOptions}
                  value={newPassPayoutStatus}
                  onChange={handleStatusChange}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-danger" onClick={() => onClose(false)}>Close</button>
            {isUpdatingPassPayout ? (
              <ThreeDots color="#000" height={50} width={50} />
            ) : (
              <button
                id="btnAdd"
                type="button"
                class="btn btn-primary"
                style={{ background: "#000 ", border: "none" }}
                onClick={handleUpdate}
              >
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_PassPayout;
