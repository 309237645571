import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import React, { useEffect } from "react";
import "./App.css";

import Categories from "./Pages/Admin/categories";
import Venue from "./Pages/Admin/venue";
import Appointment from "./Pages/Admin/appointment";
import Transaction from "./Pages/Admin/transaction";
import Review_and_Rating from "./Pages/Admin/review_and_rating";
import Ask_question from "./Pages/Admin/ask_question";
import Dashboard from "./Pages/Admin/dashboard";
import Venue_Owner from "./Pages/Admin/venue_owner";
import Instructor from "./Pages/Admin/instructor";
import Customer from "./Pages/Admin/customer";
import Workshop from "./Pages/Admin/workshop";

import Blogs_news from "./Pages/Admin/blogs_news";

import Location from "./Pages/Admin/location";
import Questions from "./Pages/Admin/questions";
import Messages from "./Pages/Admin/messages";
import Email_templates from "./Pages/Admin/email_templates";
import Users from "./Pages/Admin/users";
import Sub_Categories from "./Pages/Admin/Sub_categories";
import Packages from "./Pages/Admin/packages";
import Website_pages from "./Pages/Admin/website_pages";
import Assigned_packages from "./Pages/Admin/assigned_packages";
import Banners from "./Pages/Admin/banner.js";
import Review from "./Pages/Admin/review";
import News from "./Pages/Admin/news";
import Blog from "./Pages/Admin/blog";
import Pages from "./Pages/Admin/pages";
import VenueownerAppointment from "./Pages/Venue_owner/appointment";
import VenueownerVenue from "./Pages/Venue_owner/venue";
import VenueownerCategories from "./Pages/Venue_owner/categories";
import VenueownerReview from "./Pages/Venue_owner/review";
import VenueownerPackages from "./Pages/Venue_owner/packages";
import VenueownerAssignedpackages from "./Pages/Venue_owner/assigned_packages";
import VenueownerTransaction from "./Pages/Venue_owner/transaction";
import VenueownerQuestion from "./Pages/Venue_owner/questions";
import Instructor_Dashboard from "./Pages/instructor/dashboard";
import Instructor_Workshop from "./Pages/instructor/workshop";
import Instructor_Categories from "./Pages/instructor/categories";
import Sub_InstructorSub_Categories from "./Pages/instructor/Sub_categories";
import Instructor_Review from "./Pages/instructor/review";
import Instructor_Packages from "./Pages/instructor/packages";
import Instructor_Assigned_packages from "./Pages/instructor/assigned_packages";
import Instructor_Transaction from "./Pages/instructor/transaction";
import Instructor_Appointment from "./Pages/instructor/appointment";
import Instructor_Question from "./Pages/instructor/questions";
import Customer_Dashboard from "./Pages/Customer/dashboard";
import Customer_Appointment from "./Pages/Customer/appointment";
import Customer_Transaction from "./Pages/Customer/transaction";
import Venueowner_Dashboard from "./Pages/Venue_owner/dashboard";
import Wishlist from "./Pages/Customer/cust_wishlist";
import Save from "./Pages/Customer/cust_save";

import Rating from "./Pages/Customer/cust_rating";
import Message from "./Pages/Customer/cust_message";
import Question from "./Pages/Customer/cust_question";
import Login from "./Pages/login.js";
import VenueOwnerNews from "./Pages/Venue_owner/news.js";
import InstructorNews from "./Pages/instructor/news.js";
import ViewProfile from "./Pages/Admin/viewProfile.js";
import CustViewProfile from "./Pages/Customer/CustViewProfile.js";
import SignUp from "./Pages/signUp.js";
import ViewInstProfile from "./Pages/instructor/instr_Profile.js";
import ViewProfileVenue from "./Pages/Venue_owner/viewProfileVenue.js";
import InstructorPayOut from "./Pages/Admin/instructorPayOut";
import CustomerPayOut from "./Pages/Admin/customerPayOut";
import About from "./Pages/Admin/aboutUs";
import Subscriber from "./Pages/Admin/subscribers";
import Happy from "./Pages/Admin/HappyCustomer";
import Faq from "./Pages/Admin/faq";

import CancellationPolicy from "./Pages/Admin/cancellationPolicy";
import PrivacyPolicy from "./Pages/Admin/privacyPolicy";
import TermsCondition from "./Pages/Admin/terms&condition";
import Hiring from "./Pages/Admin/hiring";
import Invoice from "./Pages/instructor/invoice.js";
import UploadInvoice from "./Pages/instructor/uploadInvoice.js";
import AdminInvoice from "./Pages/Admin/adminInvoice.js";
import Events from "./Pages/Admin/events.js";
import LoginEmployee from "./Pages/Employee/login.js";
import LoginBrandReprensentative from "./Pages/Brand_Representative/login.js";
import Employee from "./Pages/Admin/employee.js";
import Pass from "./Pages/Admin/pass.js";
import BrandRepresentative from "./Pages/Admin/brandRepresentative.js";
import SchedulePass from "./Pages/Admin/schedulePass.js";
import PassManagement from "./Pages/Admin/passManagement.js";
import PassPayout from "./Pages/Admin/passPayout.js";
import InstructorPassPayout from "./Pages/instructor/passPayout.js";
import VenueOwnerPassPayout from "./Pages/Venue_owner/passPayout.js";
import VenueOwnerPassAppointments from "./Pages/Venue_owner/passAppointments.js";
import CustomerPassManagement from "./Pages/Customer/passManagement.js";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="" element={<Login />} /> */}
        <Route path="/signup" element={<SignUp />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route
          path="/venueProvider/appointment"
          element={<VenueownerAppointment />}
        />
        <Route path="/admin/categories" element={<Categories />} />
        <Route path="/admin/sub_categories" element={<Sub_Categories />} />
        <Route path="/admin/user" element={<Users />} />
        <Route path="/admin/venue_owner" element={<Venue_Owner />} />
        <Route path="/admin/instructor" element={<Instructor />} />
        <Route path="/admin/customer" element={<Customer />} />
        <Route path="/admin/venue" element={<Venue />} />
        <Route path="/admin/workshop" element={<Workshop />} />
        <Route path="/admin/appointment" element={<Appointment />} />
        <Route path="/admin/packages" element={<Packages />} />
        <Route
          path="/admin/packageManagement"
          element={<Assigned_packages />}
        />
        <Route path="/admin/transaction" element={<Transaction />} />
        <Route path="/admin/location" element={<Location />} />
        <Route path="/admin/website_pages" element={<Website_pages />} />
        <Route path="/admin/questions" element={<Questions />} />
        <Route
          path="/admin/review_and_rating"
          element={<Review_and_Rating />}
        />
        <Route path="/admin/blogs_news" element={<Blogs_news />} />
        <Route path="/admin/banner" element={<Banners />} />
        <Route path="/admin/messages" element={<Messages />} />
        <Route path="/admin/email_templates" element={<Email_templates />} />
        <Route path="/admin/ask_question" element={<Ask_question />} />
        <Route path="/admin/review" element={<Review />} />
        <Route path="/admin/news" element={<News />} />
        <Route path="/admin/blog" element={<Blog />} />
        <Route path="/admin/pages" element={<Pages />} />
        <Route path="/admin/invoice" element={<AdminInvoice />} />
        <Route path="/admin/events" element={<Events />} />
        <Route path="/admin/profile" element={<ViewProfile />} />
        <Route path="/admin/employee" element={<Employee />} />
        <Route path="/admin/pass" element={<Pass />} />
        
        <Route path="/admin/schedule" element={<SchedulePass />} />
        <Route path="/admin/brandRespresentative" element={<BrandRepresentative />} />
        <Route path="/admin/passManagement" element={<PassManagement />} />
        <Route path="/admin/passPayout" element={<PassPayout />} />
        <Route path="/venueProvider/venue" element={<VenueownerVenue />} />
        <Route
          path="/venueProvider/dashboard"
          element={<Venueowner_Dashboard />}
        />

        <Route
          path="/venueProvider/categories"
          element={<VenueownerCategories />}
        />
        <Route path="/venueProvider/review" element={<VenueownerReview />} />
        <Route
          path="/venueProvider/packages"
          element={<VenueownerPackages />}
        />
        <Route
          path="/venueProvider/packageManagement"
          element={<VenueownerAssignedpackages />}
        />
        <Route
          path="/venueProvider/transaction"
          element={<VenueownerTransaction />}
        />
        <Route
          path="/venueProvider/question"
          element={<VenueownerQuestion />}
        />
        
        <Route path="/venueProvider/passPayout" element={<VenueOwnerPassPayout />} />
        <Route path="/venueProvider/passAppointments" element={<VenueOwnerPassAppointments />} />


        <Route
          path="/instructor/dashboard"
          element={<Instructor_Dashboard />}
        />
        <Route path="/instructor/workshop" element={<Instructor_Workshop />} />
        <Route
          path="/instructor/categories"
          Instructor
          element={<Instructor_Categories />}
        />
        <Route
          path="/instructor/sub_categories"
          element={<Sub_InstructorSub_Categories />}
        />
        <Route path="/instructor/review" element={<Instructor_Review />} />
        <Route path="/instructor/packages" element={<Instructor_Packages />} />
        <Route
          path="/instructor/assigned_packages"
          element={<Instructor_Assigned_packages />}
        />
        <Route
          path="/instructor/transaction"
          element={<Instructor_Transaction />}
        />
        <Route
          path="/instructor/appointment"
          element={<Instructor_Appointment />}
        />
        <Route path="/instructor/questions" element={<Instructor_Question />} />
        <Route path="/instructor/invoice" element={<Invoice />} />
        <Route path="/instructor/uploadinvoice" element={<UploadInvoice />} />
        <Route path="/instructor/passPayout" element={<InstructorPassPayout />} />
        

        <Route path="/customer/dashboard" element={<Customer_Dashboard />} />
        <Route
          path="/customer/appointment"
          element={<Customer_Appointment />}
        />
        <Route
          path="/customer/transaction"
          element={<Customer_Transaction />}
        />

        <Route path="/customer/wishlist" element={<Wishlist />} />
        <Route path="/customer/save" element={<Save />} />
        <Route path="/customer/rating" element={<Rating />} />
        <Route path="/customer/message" element={<Message />} />
        <Route path="/customer/question" element={<Question />} />
        <Route path="/customer/profile" element={<CustViewProfile />} />
        <Route path="/customer/passManagement" element={<CustomerPassManagement />} />
        <Route path="/venueProvider/news" element={<VenueOwnerNews />} />
        <Route path="/instructor/news" element={<InstructorNews />} />
        <Route path="/instructor/profile" element={<ViewInstProfile />} />
        <Route path="/venueProvider/profile" element={<ViewProfileVenue />} />
        <Route path="/admin/instructorPayOut" element={<InstructorPayOut />} />
        <Route path="/admin/customerPayOut" element={<CustomerPayOut />} />
        <Route path="/admin/aboutUs" element={<About />} />
        <Route path="/admin/subscribersList" element={<Subscriber />} />
        <Route path="/admin/happyCustomer" element={<Happy />} />
        <Route path="/admin/faq" element={<Faq />} />

        <Route path="/admin/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/admin/terms&conditions" element={<TermsCondition />} />
        <Route path="/admin/weAreHiring" element={<Hiring />} />
        <Route
          path="/admin/cancellationPolicy"
          element={<CancellationPolicy />}
        />
         <Route path="/employee/login" element={<LoginEmployee/>} />

         <Route path="/brandRepresentative/login" element={<LoginBrandReprensentative/>} />

      </Routes>
    </BrowserRouter>
  );
}

const ProtectedRoute = ({ path }) => {
  const isAuthenticated = localStorage.getItem("user_id");

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace state={{ from: path }} />
  );
};

export default App;
