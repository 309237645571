import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import logo from "../../Static/img/ARTIQUITY_LOGO.jpeg";
import pic from "../../Static/img/adminLogo.jpeg";
import { loginBrPassManagement, loginWithOtpBr, verifyOtpBr } from "../../Api/apiUrls";

function LoginBrandRepresentative() {
  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [apiMessage, setApiMessage] = useState(""); // For showing API messages
  const [isOtpLogin, setIsOtpLogin] = useState(true); // Toggle between OTP and password login
  const [isOtpSent, setIsOtpSent] = useState(false); // Track if OTP is sent
  const [isResendEnabled, setIsResendEnabled] = useState(false); // Resend button state
  const [timer, setTimer] = useState(120); // Timer for resend OTP
  const navigate = useNavigate();

  // Timer effect
  useEffect(() => {
    let timerInterval;
    if (isOtpSent && timer > 0) {
      timerInterval = setInterval(() => setTimer((prev) => prev - 1), 1000);
    } else if (timer === 0) {
      setIsResendEnabled(true);
    }
    return () => clearInterval(timerInterval);
  }, [isOtpSent, timer]);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message
    setApiMessage(""); // Reset API message

    try {
      const response = await fetch(`${loginWithOtpBr}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Specify JSON content type
        },
        body: JSON.stringify({
          email: username, // Include the username field
          type: "br",
        }),
      });

      const jsonResponse = await response.json();
      const { success, status, message } = jsonResponse;

      if (status !== 200 || !success) {
        setError("Failed to send OTP. Please try again.");
        setApiMessage(message || "Something went wrong.");
        return;
      }

      // OTP sent successfully
      setIsOtpSent(true);
      setTimer(120); // Reset timer to 2 minutes
      setIsResendEnabled(false); // Disable resend button
      setApiMessage(message || "OTP sent successfully.");
    } catch (err) {
      setError("An error occurred while sending OTP");
    }
  };
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message
  
    // Mock Password Login API Call
    try {
      const response = await fetch(
        `${loginBrPassManagement}${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );
      const jsonResponse = await response.json();
      const { success, status, data } = jsonResponse;
  
      if (status !== 200 || !success) {
        setError("Incorrect Login ID or Password");
        return;
      }
  
      if (success) {
        // Store user data in localStorage
        localStorage.setItem("user_id", data.id);
        localStorage.setItem("token", data.token);
        localStorage.setItem("is_login", true);
        localStorage.setItem("type_of_user", data.type_of_user);
        localStorage.setItem("status", data.status);
  
        // Navigate to the pass management page
        navigate(`/brandRepresentative/passManagement`);
      }
    } catch (err) {
      setError("An error occurred while logging in");
    }
  };
  
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message
    setApiMessage(""); // Reset API message

    try {
      const response = await fetch(`${verifyOtpBr}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Specify JSON content type
        },
        body: JSON.stringify({
          email: username, // Include the username field
          type: "br",
          otp: otp, // Add OTP to the request body
        }),
      });

      const jsonResponse = await response.json();
      const { success, status, data } = jsonResponse;

      if (status !== 200 || !success) {
        setError("Invalid OTP or username");
        setApiMessage(jsonResponse.message || "Invalid OTP.");
        return;
      }

      if (success) {
        // Store user data in localStorage
        localStorage.setItem("user_id", data.id);
        localStorage.setItem("token", data.token);
        localStorage.setItem("is_login", true);
        localStorage.setItem("type_of_user", data.type_of_user);
        localStorage.setItem("status", data.status);

        // Navigate to the pass management page
        navigate(`/brandRepresentative/passManagement`);
      }
    } catch (err) {
      setError("An error occurred while logging in");
    }
  };

  return (
    <div className="row col-md-12">
      <div className="body">
        <div className="wrapper">
          <div className="form-wrapper sign-in">
            <form action="" style={{ padding: "30px" }}>
              <img style={{ width: "90%" }} src={logo} alt="Logo" />
              <br />
              <br />
              <h2>Brand Representative Login</h2>

              {error && (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {error}
                </div>
              )}
              {apiMessage && (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {apiMessage}
                </div>
              )}

              <div className="input-group">
                <input
                  type="text"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <label>Username</label>
              </div>

              {isOtpLogin ? (
                <>
                  {!isOtpSent ? (
                    <button
                      type="submit"
                      className="btnlogin"
                      onClick={handleSendOtp}
                    >
                      Send OTP
                    </button>
                  ) : (
                    <>
                      <div className="input-group">
                        <input
                          type="text"
                          required
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                        <label>OTP</label>
                      </div>
                      <button
                        type="submit"
                        className="btnlogin"
                        onClick={handleOtpSubmit}
                      >
                        Login with OTP
                      </button>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        {isResendEnabled ? (
                          <button
                            type="button"
                            className="btn-link"
                            onClick={handleSendOtp}
                          >
                            Resend OTP
                          </button>
                        ) : (
                          <span>
                            Resend OTP in {Math.floor(timer / 60)}:
                            {String(timer % 60).padStart(2, "0")}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    <button
                      type="button"
                      className="btn-link"
                      onClick={() => setIsOtpLogin(false)}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#007bff",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                    >
                      Login with Password
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="input-group">
                    <input
                      type="password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label>Password</label>
                  </div>
                  <button
                    type="submit"
                    className="btnlogin"
                    onClick={handlePasswordSubmit}
                  >
                    Login with Password
                  </button>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    <button
                      type="button"
                      className="btn-link"
                      onClick={() => {
                        setIsOtpLogin(true);
                        setIsOtpSent(false); // Reset OTP flow
                      }}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#007bff",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                    >
                      Login with OTP
                    </button>
                  </div>
                </>
              )}
            </form>
            <div style={{ display: "block" }}>
              <img
                style={{
                  width: "320px",
                  height: "400px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, .5)",
                }}
                src={pic}
                alt="Admin Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginBrandRepresentative;
