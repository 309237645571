import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_assignedpackages from "../../Components/Admin_components/add_assignedpackages.js";
import Update_assignedPackages from "../../Components/Admin_components/update_assignedPackages.js";
import Delete_assignedpackages from "../../Components/Admin_components/delete_assignedpackages.js";
import "../../Api/apiUrls";
import {
  getAdminAssignedPackages,
  assignedPackagesExcel,
  getAdminPassManagement,
} from "../../Api/apiUrls";
import ViewPassScheduleBooked from "./viewPassScheduleBooked.js";
function PassManagement() {
  const [modalAdd_assignedpackagesIsOpen, setAdd_assignedpackagesModalIsOpen] =
    useState("");
  const [
    modalUpdate_assignedPackagesIsOpen,
    setUpdate_assignedPackagesModalIsOpen,
  ] = useState(false);
  const [modalDelete_assignedpackagesIsOpen, setDelete_assignedpackagesIsOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [passManagement, setPassManagement] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedPass, setSelectedPass] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const filter = [
    { value: "All", label: "All" },
    { value: "Featured", label: "Featured" },
    { value: "Sponsored", label: "Sponsored" },
    // { value: "Gift", label: "Gift" },
  ];

  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
    { value: "News", label: "News" },
  ];
  const transaction = [
    { value: "All", label: "All" },
    { value: "Success", label: "Success" },
    { value: "Failed", label: "Failed" },
    { value: "On Hold", label: "On Hold" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Expired", label: "Expired" },
    { value: "Published", label: "Published" },
  ];
  const Pass = [
    { value: "All", label: "All" },
    { value: "Annual", label: "Annual" },
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Half_Yearly", label: "Half_Yearly" },
  ];

  const openAdd_assignedpackages = () => {
    closeAdd_assignedpackages();
    setAdd_assignedpackagesModalIsOpen(true);
  };

  const closeAdd_assignedpackages = () => {
    setAdd_assignedpackagesModalIsOpen(false);
  };
  const openUpdate_assignedPackages = () => {
    closeUpdate_assignedPackages();
    setUpdate_assignedPackagesModalIsOpen(true);
  };

  const closeUpdate_assignedPackages = () => {
    setUpdate_assignedPackagesModalIsOpen(false);
  };
  const openDelete_assignedpackages = (userId) => {
    setSelectedUserId(userId);
    closeDelete_assignedpackages();
    setDelete_assignedpackagesIsOpen(true);
  };

  const closeDelete_assignedpackages = () => {
    setDelete_assignedpackagesIsOpen(null);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption);
  };

  const handlePassChange = (selectedOption) => {
    setSelectedPass(selectedOption);
  };

  const handleTransactionChange = (selectedOption) => {
    setSelectedTransaction(selectedOption);
  };

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    fetch(getAdminPassManagement)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const passManagement = data.data.PassManagement;
        console.log(passManagement);
        setPassManagement(passManagement);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Pass Management value:", passManagement);
      });
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPassManagement, setFilteredPassManagement] = useState(
    []
  );

  useEffect(() => {
    // Filter packageManagement based on selected filters and search query
    const newFilteredPassManagement = passManagement.filter((item) => {
      const statusCondition =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        item.status === selectedStatus.value;
      const filterCondition =
        !selectedFilter ||
        selectedFilter.value === "All" ||
        item.filter === selectedFilter.value;
      const typeCondition =
        !selectedType ||
        selectedType.value === "All" ||
        item.pass.type === selectedType.value;
      const passCondition =
        !selectedPass ||
        selectedPass.value === "All" ||
        item.pass.duration === selectedPass.value;
      const transactionCondition =
        !selectedTransaction ||
        selectedTransaction.value === "All" ||
        item.transaction?.transaction_status === selectedTransaction.value;

      const searchCondition = Object.values(item).some((value) => {
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some((subValue) => {
            if (typeof subValue === "string") {
              return subValue.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
          });
        } else if (typeof value === "string") {
          return value.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return false;
      });

      return (
        statusCondition &&
        filterCondition &&
        typeCondition &&
        passCondition &&
        transactionCondition &&
        searchCondition
      );
    });

    // Update the state with the filtered packageManagement
    setFilteredPassManagement(newFilteredPassManagement);
  }, [
    selectedStatus,
    selectedFilter,
    selectedType,
    selectedPass,
    selectedTransaction,
    searchQuery,
    passManagement,
  ]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);

    // Format the date part
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    // Format the time part
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",

      hour12: true,
    });

    return `${datePart} - ${timePart}`;
  };

  function handleDownloadClick() {
    window.open(assignedPackagesExcel, "_blank");
  }
  
  const [modalUpdate_packagesIsOpen, setUpdate_packagesModalIsOpen] =
    useState(false);
    const closeUpdate_packages = () => {
      setUpdate_packagesModalIsOpen(false);
    };
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    const handleClick = (id) => {
      setUpdate_packagesModalIsOpen(true);
      setSelectedPackageId(id)
    };

  return (
    <div>
      <Header></Header>
      {modalUpdate_packagesIsOpen && (
        <ViewPassScheduleBooked
          id={selectedPackageId}
          onClose={closeUpdate_packages}
        />
      )}
      {modalAdd_assignedpackagesIsOpen && (
        <Add_assignedpackages onClose={closeAdd_assignedpackages} />
      )}
      {modalUpdate_assignedPackagesIsOpen && (
        <Update_assignedPackages onClose={closeUpdate_assignedPackages} />
      )}
      {modalDelete_assignedpackagesIsOpen && (
        <Delete_assignedpackages
          userId={selectedUserId}
          onClose={closeDelete_assignedpackages}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Pass Management</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Package Management</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="row">
                <div class="child col-md-2" style={{ padding: "5px" }}>
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "37px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width: "100%",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
                <div class=" col-md-2 " style={{ padding: "5px" }}>
                  <Select
                    options={status}
                    placeholder="Select Status"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  ></Select>
                </div>

                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={Pass}
                    placeholder="Select pass"
                    value={selectedPass}
                    onChange={handlePassChange}
                  ></Select>
                </div>
                <div class="  col-md-2 " style={{ padding: "5px" }}>
                  <Select
                    options={transaction}
                    placeholder="Select transaction"
                    value={selectedTransaction}
                    onChange={handleTransactionChange}
                  ></Select>
                </div>
              </div>
            </div>
            {/* <div
              className="col-md-12"
              style={{ cursor: "pointer", textAlign: "end" }}
              onClick={handleDownloadClick}
            >
              Download
              <i style={{ fontSize: "20px" }} class="bi bi-download"></i>
            </div> */}
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : passManagement.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.no</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Pass</th>
                      <th scope="col">Details</th>
                      {/* <th scope="col">Venue Details</th> */}
                      <th scope="col">Order ID</th>
                      <th scope="col">Price</th>
                      <th scope="col">Brand Respresentative</th>
                      <th scope="col">Brand Representative Commission (in percentage)</th>
                      <th scope="col">Start Month</th>
                      <th scope="col">End Month</th>
                      <th scope="col">Transaction Status</th> 
                      <th scope="col">Purchased At</th>
                      
                      <th scope="col">Status</th> 
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {/* {filteredPassManagement.map((item, index) => ( */}
                    {passManagement.map((item, index) => (
                      <tr key={passManagement.id}>
                        <th scope="row">{index + 1}</th>

                        {/* <td>
                          {item?.transaction?.order_id || "---"}
                          <br />
                        </td> */}
                        <td>
                          {item?.users?.name || "---"}
                          <br />
                          {item?.users?.email || "---"}
                          <br />
                          {item.users?.mobileNumber || "---"}
                          <br />

                        </td>
                        <td>
                          {/* {item.venue
                            ? item.venue.name
                            : item.workshop
                            ? item.workshop.name
                            : item.news
                            ? item.news.title
                            : "--"} */}
                             {item?.passes?.title || "---"} <br />
                             {item?.passes?.duration_type || "---"}
                        </td>
                        <td style={{textAlign:'center'}}>
                         <a
                            onClick={() =>
                             handleClick(item.id)
                            }
                          >
                            <i class="bi bi-eye-fill"></i>
                          </a>
                          <br />
                        </td>
                        {/* <td>
                          {item?.venue?.name || "---"}
                          <br />

                          <b>City:</b>{item?.venue?.city?.name || "---"}

                          <br />
                          <b>Area:</b>{item?.venue?.area?.name || "---"}
                          <br />
                        </td> */}

                        <td>
                          {item?.transaction?.order_id || "---"}
                          <br />
                        </td>
                        <td>
                          {item?.price || "---"}
                          <br />
                        </td>
                        <td>
                          {item?.brand_representative?.name || "---"}
                          <br />
                          {item?.brand_representative?.code || "---"}
                          <br />
                          {item?.brand_representative?.email || "---"}
                          <br />
                          {item?.brand_representative?.mobileNumber || "---"}
                          <br />
                        </td>
                        <td>
                          {item?.br_commission || "0"}
                          <br />
                        </td>
                        <td>
                          {/* {formatCreatedAt(item.dateOfActivation || "---")} */}
                          {item?.start_month || "---"}
                          <br />
                        </td>
                        <td>
                          {/* {formatCreatedAt(item.dateOfExpiry || "---")} */}
                          {item?.end_month || "---"}
                          <br />
                        </td>
                        <td>
                          {item?.transaction?.transaction_status || "---"}
                          <br />
                        </td>

                        <td>
                          {formatCreatedAt(item?.created_at || "---")}
                          <br />
                        </td>
                       
                        <td>
                          {item?.status || "---"}
                        </td>
                         
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredPassManagement.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default PassManagement;
