import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./Style.css";
import "../../Api/apiUrls";
import {
  API_URL_NEW,
  API_URL,
  getUserId,
  logOut,
  loginCheck,
  Update_MarkClicked,
} from "../../Api/apiUrls";
import useWebSocketService from "../../websocket_management/WebSocketServer";
import NotiTune from "../../Static/tone/announcement_sound.mp3";
import NotivibeIcon from "../../Static/img/A_LOGO.jpeg";
import Cookies from 'js-cookie';

function Header() {
  // var is_login = localStorage.getItem("is_login");
  // if (is_login == null || is_login == false) {
  //   window.location.href = "http://3.110.1.182/";
  // }
  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      window.location.reload();
      localStorage.setItem('hasReloaded', 'true');

    }
  }, []);
  function init() {
    const urlParams = new URLSearchParams(window.location.search);

    // if (localStorage.getItem('user_id') === null || localStorage.getItem('user_id') === "") {
    //     localStorage.clear();
    //     window.location.href = `${API_URL_NEW}`;
    // } else
    if (urlParams.has("signOut")) {
      localStorage.clear();
      window.location.href = `${API_URL_NEW}`;
    }
  }



  const user_id = localStorage.getItem("user_id");

  const { getNotiiiii, disconnect, setOnMessageHandler, onmessage } =
    useWebSocketService(user_id);

  useEffect(() => {
    setOnMessageHandler((message) => {
      // Handle incoming messages here
      Notification.requestPermission();

      if (!Array.isArray(message.data)) {
        showNotification(message.notification_message);
      }
      const newData = Array.isArray(message.data) ? message.data : [message];

      // console.log(newData);

      // Update state using a callback to avoid issues with asynchronous state updates
      setNotiList((prevNotifications) => [...newData, ...prevNotifications]);
    });

    // Disconnect on component unmount
    return () => {
      disconnect();
    };
  }, [user_id]);

  const showNotification = (notification_message) => {
    if (Notification.permission === "granted") {
      const notification = new Notification("Artiquity", {
        body: notification_message,
        vibrate: [200, 100, 200],
        icon: NotivibeIcon,
        sound: NotiTune,
        dir: "auto",
      });

      // You can handle click events or other events here
      notification.onclick = () => {
        console.log("Notification clicked.");
      };

      // Position the notification in the bottom right corner
    } else {
      Notification.requestPermission();
      console.error("Notification permission denied.");
    }
  };

  const [NotiList, setNotiList] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState(10);
  const [isShowMoreClicked, setIsShowMoreClicked] = useState(false);

  const UpdateMarkClicked = async () => {
    const user_id = localStorage.getItem("user_id");
    const url = `${Update_MarkClicked}?user_id=${user_id}`;

    try {
      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Unable to update");
      }

      const data = await response.json();
      // console.log(data);
      console.log("Success");
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  const formatNotificationDateTime = (created_at) => {
    const date = new Date(created_at);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const handleNotificationClick = (notification) => {
    // console.log(notification);
    console.log(notification.id);
    if (!notification.userIsRead) {
      // Update the notification object
      const updatedNotification = { ...notification, userIsRead: true };
      // Find the index of the clicked notification in the current notification list
      const notificationIndex = NotiList.findIndex(
        (notif) => notif.id === updatedNotification.id
      );
      // Create a new array with the updated notification
      const updatedNotiList = [...NotiList];
      updatedNotiList[notificationIndex] = updatedNotification;
      // Update the state with the new array
      setNotiList(updatedNotiList);
      // Call the function to update the is_seen status in the database
      // UpdateBoardTemplate(notification.id);
    }

    // console.log('Clicked notification:', notification.where_to);
    // if (notification.where_to === "myboard") {
    //   window.location.href = `${redirect_link}/employee/myBoard/?id=${notification.action_id}`;
    // } else if (notification.where_to === "customboard") {
    //   window.location.href = `${redirect_link}/employee/customBoard/?id=${notification.other_id}&t_id=${notification.action_id}`;
    // } else if (notification.where_to === "customBoardMain") {
    //   // setNotificationActionId(notification.action_id);
    //   window.location.href = `${redirect_link}/employee/customBoardMain`;
    // } else if (notification.where_to === "meet" || notification.where_to === "consultation") {
    //   window.location.href = `${redirect_link}${notification.redirect_to[0].link}`;
    // }
  };

  const handleShowMore = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setVisibleNotifications(NotiList.length);
    setIsShowMoreClicked(true);
  };

  // const handleShowLess = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setIsShowMoreClicked(false);
  // };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const dropdownStyles = {
    maxHeight: "450px",
    maxWidth: "400px",
    minWidth: "350px",
    overflowY: "auto",
    scrollbarWidth: "thin", // For Firefox
    scrollbarColor: "#888 #ddd", // For Firefox

    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // For WebKit browsers
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#aaa", // For WebKit browsers
    },
    // position: 'absolute',
    // left: '50%',
    transform: windowWidth <= 768 ? "translateX(-80%)" : "",

    // borderRadius:4,boxShadow: '2px 2px #5555552b'
  };

  const countNotClickedNotifications = () => {
    return NotiList.filter((notification) => !notification.userIsRead).length;
  };

  // console.log(NotiList);
  // const myCookieValue = Cookies.get('cookieLoggedIn');
  // console.log(myCookieValue);
  // useEffect(() => {
  //   // Check if cookie does not exist
  //   if (!myCookieValue) {
  //     // Redirect to 'artiquity.in'
  //     window.location.href = `${API_URL_NEW}?signOut`;
  //   }
  // }, [myCookieValue]);
  const [token, setToken] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    setToken(token);


    // Fetch from the API
    fetch(`${loginCheck}?token=${token}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (data.data.status === "Reported") {
            console.log("User is reported, clearing local storage.");
            alert("Your Profile has been reported. Please contact cs@artiquity.in.");
            localStorage.clear();
            window.location.href = `${API_URL_NEW}?signOut`;
          } else {
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("user_id", data.data.id);
            localStorage.setItem("date_of_expiry", data.data.date_of_expiry);
            localStorage.setItem("userStatus", data.data.status);

          }
        } else {
          console.log("Not there in database");
          alert("Your Session is Expired. Please Login.");
          window.location.href = `${API_URL_NEW}?signOut`;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);


  init();
  // function checkValues () {
  //   const userId = localStorage.getItem('user_id');
  //   const token = localStorage.getItem('token');
  //   const userStatus = localStorage.getItem('userStatus');
  //   const dateOfExpiry = localStorage.getItem('date_of_expiry');

  //   // Check if any of the values are missing
  //   if (!userId || !token || !userStatus || !dateOfExpiry) {
  //     // Redirect to index.html
  //     localStorage.clear();
  //     window.location.href = `${API_URL_NEW}?signOut`;
  //   }
  // }
  const [isIntegrateMenuOpen, setIntegrateMenuOpen] = useState(false);
  const [isIntegrateMenuOpen2, setIntegrateMenuOpen2] = useState(false);
  const currentURL = window.location.href;
  // console.log(currentURL);

  // function handleSignOut() {
  //   localStorage.removeItem("is_login");

  //   window.location.href = "http://3.110.1.182/";
  // }

  const customerId = localStorage.getItem("user_id");

  const handleSignOut = async () => {
    try {
      const response = await fetch(logOut + customerId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        localStorage.clear();
        window.location.href = `${API_URL_NEW}?signOut`;
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const [addClass, setAddClass] = useState(false);

  useEffect(() => {
    if (addClass) {
      document.body.classList.add("toggle-sidebar");
    } else {
      document.body.classList.remove("toggle-sidebar");
    }
  }, [addClass]);


  useEffect(() => {
    fetch(`${getUserId}${user_id}`)
      .then((response) => response.json())
      .then((data) => {
        const user = data.data.user;

        // Update profile image
        document.getElementById(
          "profileImage"
        ).src = `${API_URL}/${user.profile_image}`;

        // Update profile name
        document.getElementById("profileName").innerText = user.name;
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  return (
    <>
      <header
        id="header"
        class="header fixed-top d-flex align-items-center"
        style={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.329)" }}
      >
        <div class="d-flex align-items-center justify-content-between">
          <a
            href="https://artiquity.in/"
            class="logo d-flex align-items-center"
          >
            <img src={require("../../Static/img/ARTIQUITY_LOGO.jpeg")} />
            <span class="d-none d-lg-block"></span>
          </a>

          <a onClick={() => setAddClass(!addClass)}>
            <i class="bi bi-list toggle-sidebar-btn"></i>
          </a>
        </div>

        <nav class="header-nav ms-auto">
          <ul class="d-flex align-items-center">
            {/* <div className="mr-3">
              <i
                className="bi bi-bell"
                style={{
                  fontSize: "20px",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                  4<span className="visually-hidden">unread messages</span>
                </span>
              </i>
            </div> */}

            <li class="nav-item dropdown pe-3">
              <a
                class="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
                onClick={() => UpdateMarkClicked()}
              >
                <div className="mr-3">
                  <i
                    className="bi bi-bell"
                    style={{
                      fontSize: "20px",
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                      {countNotClickedNotifications()}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </i>
                </div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-right pb-2"
                style={dropdownStyles}
                onClick={(e) => e.preventDefault()}
              >
                <span className="dropdown-item dropdown-header">
                  {countNotClickedNotifications()} Notifications
                </span>
                <div className="dropdown-divider"></div>
                {NotiList.slice(0, visibleNotifications).map(
                  (notification, index) => (
                    <div
                      key={index}
                      style={{
                        borderRadius: 0,
                        boxShadow: "1px 1px #5555552b",
                      }}
                    >
                      <a
                        href="#"
                        className={`dropdown-item ${notification.userIsRead ? "text-muted" : ""
                          }`}
                        onClick={() => handleNotificationClick(notification)}
                        style={{
                          backgroundColor: "white",
                          textWrap: "wrap",
                          padding: "9px",
                          color: "black",
                        }}
                      >
                        {notification.notification_message}
                      </a>
                      <span
                        className="float-right text-sm"
                        style={{ fontSize: "smaller", paddingRight: "9px" }}
                      >
                        {formatNotificationDateTime(notification.created_at)}
                      </span>
                      <hr style={{ margin: "1.6rem 0" }} />
                    </div>
                  )
                )}
                {NotiList.length > visibleNotifications && (
                  <div className="dropdown-item text-center">
                    <p
                      className={`dropdown-footer ${isShowMoreClicked ? "clicked" : ""
                        }`}
                      onClick={handleShowMore}
                      style={{ cursor: "pointer" }}
                    >
                      <b style={{ fontSize: 16 }}>
                        {isShowMoreClicked ? "Show Less" : "Show More"}
                      </b>
                    </p>
                  </div>
                )}
                {/* <div className="dropdown-divider"></div> */}
              </div>
            </li>

            <li class="nav-item dropdown pe-3">
              <a
                class="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                <img
                  id="profileImage"
                  src={""}
                  // src={require("../../Static/img/messages-4.jpg")}
                  alt="Profile"
                  class="rounded-circle"
                  style={{ aspectRatio: "1/1", borderRadius: "7px" }}
                />
                <span class="d-none d-md-block dropdown-toggle ps-2">
                  Instructor
                </span>
              </a>
              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li class="dropdown-header">
                  <h6 id="profileName"></h6>
                  <span>Role: Instructor</span>
                </li>

                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <Link
                    class="dropdown-item d-flex align-items-center"
                    to={{
                      pathname: "/instructor/profile",
                      search: `?token=${token}`,
                    }}
                  >
                    <i class="bi bi-person-circle"></i>
                    <span>View Profile</span>
                  </Link>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="#"
                    onClick={handleSignOut}
                  >
                    <i class="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>

      <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">
          <li class="nav-item">
            <Link
              to={{
                pathname: "/instructor/workshop",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                  `http://localhost:3000/instructor/workshop?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-shop-window"></i>
              {/* <i class="bi bi-list"></i> */}
              <span style={{ marginLeft: "3%" }}>Workshop</span>
            </Link>
          </li>

          {/* <li class="nav-item">
            <Link
              to="/instructor/packages"
              className={
                "nav-link" +
                (currentURL === "http://localhost:3000/instructor/packages"
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-shop-window"></i>
           
              <span style={{ marginLeft: "3%" }}>Packages</span>
            </Link>
          </li> */}
          <li class="nav-item">
            <Link
              to={{
                pathname: "/instructor/assigned_packages",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                  `http://localhost:3000/instructor/packagesMangement?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-cash-stack"></i>
              {/* <i class="bi bi-list"></i> */}
              <span style={{ marginLeft: "3%" }}>Package Management</span>
            </Link>
          </li>
          {/* <li class="nav-item">
            <Link
              to="/instructor/transaction"
              className={
                "nav-link" +
                (currentURL === "http://localhost:3000/instructor/transaction"
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-wallet2"></i>
              
              <span style={{ marginLeft: "3%" }}>Transaction</span>
            </Link>
          </li> */}
          <li class="nav-item">
            <Link
              to={{
                pathname: "/instructor/appointment",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                  `http://localhost:3000/instructor/appointment?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-calendar2-event"></i>
              {/* <i class="bi bi-list"></i> */}
              <span style={{ marginLeft: "3%" }}>Appointment</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link
              to={{
                pathname: "/instructor/news",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                  `http://localhost:3000/instructor/news?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-newspaper"></i>
              <span style={{ marginLeft: "3%" }}> News</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/instructor/questions",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                  `http://localhost:3000/instructor/questions?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-question-circle"></i>
              {/* <i class="bi bi-list"></i> */}
              <span style={{ marginLeft: "3%" }}>Question</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link
              to={{
                pathname: "/instructor/invoice",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                  `http://localhost:3000/instructor/invoice?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-card-heading"></i>
              {/* <i class="bi bi-list"></i> */}
              <span style={{ marginLeft: "3%" }}>Invoice</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link
              to={{
                pathname: "/instructor/uploadinvoice",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                  `http://localhost:3000/instructor/uploadinvoice?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-card-heading"></i>
              {/* <i class="bi bi-list"></i> */}
              <span style={{ marginLeft: "3%" }}>Upload Invoice</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link
              to={{
                pathname: "/instructor/passPayout",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                  `http://localhost:3000/instructor/passPayout?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-wallet"></i>
              <span style={{ marginLeft: "3%" }}>Pass Payout</span>
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Header;
