import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import "../../Api/apiUrls";
import { getAdminPass, updateAdminPass } from "../../Api/apiUrls";
import AddPass from "../../Components/Admin_components/addPass.js";
import UpdatePass from "../../Components/Admin_components/updatePass.js";
import Toggle from "react-toggle";

function Pass() {
  const [modalAddPassIsOpen, setAddPassModalIsOpen] = useState(false);
  const [modalUpdatePassIsOpen, setUpdatePassModalIsOpen] = useState(false);
  const [pass, setPass] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPass, setSelectedPass] = useState({});
  const [toggleStates, setToggleStates] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [durationFilter, setDurationFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const openAddPass = () => setAddPassModalIsOpen(true);
  const closeAddPass = () => setAddPassModalIsOpen(false);
  const closeUpdatePass = () => setUpdatePassModalIsOpen(false);

  const openUpdatePass = (pass) => {
    setSelectedPass(pass);
    setUpdatePassModalIsOpen(true);
  };

  const toggleSwitch = async (passId) => {
    try {
      const newToggleState = toggleStates[passId] === 1 ? 0 : 1;
      const newStatus = newToggleState === 1 ? "Active" : "Inactive";
  
      const response = await fetch(updateAdminPass, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          status: newStatus,
          pass_id: passId,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to update toggle state.");
      }
  
      const data = await response.json();
  
      if (data.success) {
        // Update the toggle state
        setToggleStates((prevState) => ({
          ...prevState,
          [passId]: newToggleState,
        }));
  
        // Update the status in the pass state
        setPass((prevPass) =>
          prevPass.map((item) =>
            item.id === passId ? { ...item, status: newStatus } : item
          )
        );
  
        console.log("Toggle and status updated successfully:", data);
      } else {
        alert("Failed to update the toggle state. Please try again.");
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
      alert("Failed to update the toggle state. Please try again.");
    }
  };
  

  useEffect(() => {
    fetch(getAdminPass)
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        const passData = data.data.Pass || [];
        setPass(passData);

        const initialToggleStates = passData.reduce((acc, item) => {
          acc[item.id] = item.status === "Active" ? 1 : 0;
          return acc;
        }, {});
        setToggleStates(initialToggleStates);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setIsLoading(false));
  }, []);

  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  const handleDurationFilterChange = (event) =>
    setDurationFilter(event.target.value);

  const handleStatusFilterChange = (event) => setStatusFilter(event.target.value);

  const filteredPasses = pass.filter((item) => {
    const matchesSearch = Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const matchesDuration =
      durationFilter === "" || item.duration_type === durationFilter;

    const matchesStatus =
      statusFilter === "" || item.status.toLowerCase() === statusFilter.toLowerCase();

    return matchesSearch && matchesDuration && matchesStatus;
  });

  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div>
      <Header />
      {modalAddPassIsOpen && <AddPass onClose={closeAddPass} />}
      {modalUpdatePassIsOpen && (
        <UpdatePass
          {...selectedPass}
          onClose={closeUpdatePass}
        />
      )}
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Passes</h1>
        </div>
        <section className="section">
          <div className="card p-2">
            <div className="row mt-2">
              <div className="col-md-3">
                <input
                  onChange={handleSearchChange}
                  value={searchQuery}
                  id="myInput"
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  value={durationFilter}
                  onChange={handleDurationFilterChange}
                >
                  <option value="">All Durations</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                >
                  <option value="">All Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="col-md-3 text-end">
              <button
                  id="btnNewUser"
                  type="button"
                  data-toggle="modal"
                  data-target="#modalAdd"
                  class=" btn-round"
                  onClick={openAddPass}
                >
                  <i class="bi bi-person-add"></i> Add Pass
                </button>
              </div>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center mt-5">
                <TailSpin color="black" width={50} />
              </div>
            ) : filteredPasses.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Status Toggle</th>
                      <th scope="col">Title</th>
                      <th scope="col">Description</th>
                      <th scope="col">MRP</th>
                      <th scope="col">Price</th>
                      <th scope="col">Duration</th>
                      <th scope="col">Sold</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPasses.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>
                          <Toggle
                            checked={toggleStates[item.id] === 1}
                            onChange={() => toggleSwitch(item.id)}
                          />
                        </td>
                        <td>{item.title || "--"}</td>
                        <td>{item.description || "--"}</td>
                        <td>{item.mrp || "--"}</td>
                        <td>{item.price || "--"}</td>
                        <td>{item.duration_type || "--"}</td>
                        <td>{item.sell || "0"}</td>
                        <td>{item.status || "--"}</td>
                        <td>{formatCreatedAt(item.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <h3 className="text-center mt-4">No data available</h3>
            )}
          </div>
          <p className="text-center bg-dark text-white p-2">
            {filteredPasses.length} Total
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Pass;
