import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_transaction from "../../Components/Admin_components/add_transactions.js";
import Delete_transaction from "../../Components/Admin_components/delete_transaction.js";
import "../../Api/apiUrls";
import { getAdminTransaction, transactionExcel } from "../../Api/apiUrls";
import ViewTransactionPassScheduleBooked from "./viewTransactionPassScheduleBooked.js";
function Transaction() {
  const [modalAdd_transactionIsOpen, setAdd_transactionModalIsOpen] =
    useState("");
  const [modalDelete_transactionIsOpen, setDelete_transactionIsOpen] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]); // Assuming you have a state for transactions
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const transaction = [
    { value: "All", label: "All" },
    { value: "Success", label: "Success" },
    { value: "On Hold", label: "On Hold" },
    { value: "Failed", label: "Failed" },
  ];
  const openAdd_transaction = () => {
    closeAdd_transaction();
    setAdd_transactionModalIsOpen(true);
  };

  const closeAdd_transaction = () => {
    setAdd_transactionModalIsOpen(false);
  };
  const openDelete_transaction = (userId) => {
    setSelectedUserId(userId);
    closeDelete_transaction();
    setDelete_transactionIsOpen(true);
  };

  const closeDelete_transaction = () => {
    setDelete_transactionIsOpen(null);
  };


  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    fetch(getAdminTransaction)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const transactions = data.data.Transaction;
        console.log(transactions);
        setTransactions(transactions);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Transactions value:", transactions);
      });
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Filter transactions based on selected filters and search query
    const newFilteredTransactions = transactions.filter((transaction) => {
      const typeCondition =
        !selectedType ||
        selectedType.value === "All" ||
        transaction.type === selectedType.value;
      const statusCondition =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        transaction.user.status === selectedStatus.value;
      const transactionCondition =
        !selectedTransaction ||
        selectedTransaction.value === "All" ||
        transaction.transaction_status === selectedTransaction.value;
      const searchMatch = Object.values(transaction).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())
      );
      // Return true if the transaction matches all conditions
      return (
        typeCondition && statusCondition && transactionCondition && searchMatch
      );
    });

    // Update the state with the filtered transactions
    setFilteredTransactions(newFilteredTransactions);
  }, [
    selectedType,
    selectedStatus,
    selectedTransaction,
    searchQuery,
    transactions,
  ]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  function handleDownloadClick() {
    window.open(transactionExcel, "_blank");
  }
  const [modalUpdate_packagesIsOpen, setUpdate_packagesModalIsOpen] =
  useState(false);
  const closeUpdate_packages = () => {
    setUpdate_packagesModalIsOpen(false);
  };
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const handleEyeClick = (id) => {
    setUpdate_packagesModalIsOpen(true);
    setSelectedPackageId(id)
  };
  
  return (
    <div>
      <Header></Header>
      {modalUpdate_packagesIsOpen && (
        <ViewTransactionPassScheduleBooked
          id={selectedPackageId}
          onClose={closeUpdate_packages}
        />
      )}
      {/* {modalAdd_assignedpackagesIsOpen && (
        <Add_assignedpackages onClose={closeAdd_assignedpackages} />
      )}
      {modalUpdate_assignedPackagesIsOpen && (
        <Update_assignedPackages onClose={closeUpdate_assignedPackages} />
      )}
      {modalDelete_assignedpackagesIsOpen && (
        <Delete_assignedpackages
          userId={selectedUserId}
          onClose={closeDelete_assignedpackages}
        />
      )} */}
      {modalAdd_transactionIsOpen && (
        <Add_transaction onClose={closeAdd_transaction} />
      )}
      {modalDelete_transactionIsOpen && (
        <Delete_transaction
          userId={selectedUserId}
          onClose={closeDelete_transaction}
        />
      )}

      {/* {modalAdd_AboutUsIsOpen && <Add_AboutUs onClose={closeAdd_AboutUs} />} */}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Transaction</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Transaction</li>
            </ol>
          </nav> */}
        </div>
        <section class="section"></section>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="row">
                <div class="child col-md-2">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "38px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
                <div class="child ml-2 col-md-2">
                  <Select
                    options={type}
                    placeholder="Select Type"
                    onChange={(selectedOption) =>
                      setSelectedType(selectedOption)
                    }
                  ></Select>
                </div>
                <div class="child ml-2 col-md-2">
                  <Select
                    options={status}
                    placeholder="Select Status"
                    onChange={(selectedOption) =>
                      setSelectedStatus(selectedOption)
                    }
                  ></Select>
                </div>
                <div class="child ml-2 col-md-2">
                  <Select
                    options={transaction}
                    placeholder="Transaction Status"
                    onChange={(selectedOption) =>
                      setSelectedTransaction(selectedOption)
                    }
                  ></Select>
                </div>
              </div>
            </div>
            <div
              className="col-md-12"
              style={{ cursor: "pointer", textAlign: "end" }}
              onClick={handleDownloadClick}
            >
              Download
              <i
                style={{ fontSize: "20px", marginLeft: "10px" }}
                class="bi bi-download"
              ></i>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>

            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : transactions.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col"> Name of Listing</th>
                      <th scope="col">Type</th>
                      <th scope="col">Slot</th>
                      <th scope="col">Seats</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Customer Details</th>

                      <th scope="col">Transaction ID</th>
                      <th scope="col">Status of Transaction </th>
                      <th scope="col">Payment Mode</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Action</th>
                      {/* <th scope="col">Status</th> */}
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredTransactions.map((transactions, index) => (
                      <tr key={transactions.id}>
                        <th scope="row">{index + 1}</th>

                        <td>
                          {transactions.title || "--"}
                          <br />
                          {transactions.order_id || "--"}
                          <br />
                        </td>
                        <td>
                          {transactions.type || "--"}
                          <br />
                        </td>
                        <td>
                          {transactions?.slot || "--"}
                          <br />
                          {transactions?.type === "Venue"
                            ? formatCreatedAt(
                                transactions?.bookingSlot?.startDate
                              )
                            : formatCreatedAt(transactions?.workshopSlot?.date)}
                          <br />
                          <br />
                        </td>
                        <td>
                          {transactions.seats || "--"}
                          <br />
                        </td>
                        <td>
                          {transactions.amount || "--"}
                          <br />
                        </td>
                        <td>
                          {transactions.user?.name || "--"}
                          <br />
                          {transactions.user?.mobileNumber || "--"}
                          <br />
                          {transactions.user?.email || "--"}
                          <br />
                        </td>

                        <td>
                          {transactions.payment_id || "--"}
                          <br />
                        </td>
                        <td>
                          {transactions.transaction_status || "--"}
                          <br />
                        </td>
                        <td>
                          {transactions.mode || "--"}
                          <br />
                        </td>
                        <td>
                          {formatCreatedAt(transactions.created_at)}
                          <br />
                        </td>
                        <td style={{textAlign:'center'}}>
                         <a
                            onClick={() =>
                              handleEyeClick(transactions.id)
                            }
                          >
                            <i class="bi bi-eye-fill"></i>
                          </a>
                          <br />
                        </td>
                        {/* <td>
                          <b>{transactions.user.status || "--"}</b>
                          <br />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredTransactions.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Transaction;
