import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Update_PassPayout from "../../Components/Admin_components/update_pass_payout.js";
import { getInstructorAndVpPassPayout } from "../../Api/apiUrls";
import BankDetailsPopup from "../../Components/Admin_components/bankDetailsPopup.js";

function InstructorPassPayout() {
  const [payout, setPayout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalUpdate_PassPayoutIsOpen, setUpdate_PassPayoutModalIsOpen] = useState(false);
  const [selectedpassPayoutId, setselectedpassPayoutId] = useState(null);
  const [selectedpassPayoutStatus, setselectedpassPayoutStatus] = useState(null);
  const [selectedBankDetails, setSelectedBankDetails] = useState({});
  const [isBankDetailsPopupOpen, setIsBankDetailsPopupOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [workshopSlotStatusFilter, setWorkshopSlotStatusFilter] = useState("");

  const openUpdate_passPayout = (passPayoutId, passPayoutStatus) => {
    setselectedpassPayoutId(passPayoutId);
    setselectedpassPayoutStatus(passPayoutStatus);
    closeUpdate_passPayout();
    setUpdate_PassPayoutModalIsOpen(true);
  };

  const openBankDetailsPopup = (bankDetails) => {
    setSelectedBankDetails(bankDetails);
    setIsBankDetailsPopupOpen(true);
  };

  const closeBankDetailsPopup = () => {
    setIsBankDetailsPopupOpen(false);
  };

  const closeUpdate_passPayout = () => {
    setUpdate_PassPayoutModalIsOpen(false);
  };

  const instructorId = localStorage.getItem("user_id");
  useEffect(() => {
    fetch(`${getInstructorAndVpPassPayout}?ide=${instructorId}&type=workshop`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const payout = data.data?.PassManagementScheduleBooked || [];
        setPayout(payout);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredInstPayout = Array.isArray(payout)
  ? payout.filter((payout) => {
      const searchLower = searchQuery.toLowerCase();

      const matchesVenue = payout.venue?.name?.toLowerCase().includes(searchLower);
      const matchesCustomer = payout.pass_management?.users?.name?.toLowerCase().includes(searchLower);
      const matchesPass = payout.passes?.title?.toLowerCase().includes(searchLower);
      const matchesWorkshop = payout.pass_shedule?.workshop?.name?.toLowerCase().includes(searchLower);

      const matchesSearch = matchesVenue || matchesCustomer || matchesPass || matchesWorkshop;

      const matchesStatusFilter = statusFilter
        ? payout.status?.toLowerCase() === statusFilter.toLowerCase()
        : true;

      const matchesWorkshopSlotStatusFilter = workshopSlotStatusFilter
        ? payout.pass_shedule?.workshop_slot?.status?.toLowerCase() ===
          workshopSlotStatusFilter.toLowerCase()
        : true;

      return matchesSearch && matchesStatusFilter && matchesWorkshopSlotStatusFilter;
    })
  : [];


  return (
    <div>
      <Header />
      {modalUpdate_PassPayoutIsOpen && (
        <Update_PassPayout
          onClose={closeUpdate_passPayout}
          passPayoutId={selectedpassPayoutId}
          passPayoutStatus={selectedpassPayoutStatus}
        />
      )}
      {isBankDetailsPopupOpen && (
        <BankDetailsPopup
          isOpen={isBankDetailsPopupOpen}
          onClose={closeBankDetailsPopup}
          bankDetails={selectedBankDetails}
        />
      )}

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Pass PayOut</h1>
        </div>
        <section className="section">
          <div className="card p-2">
            <div className="parent col-md-12 row mt-2" style={{ marginBottom: "10px" }}>
              <div className="col-md-6">
                <div className="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
              </div>

              <div className="child col-md-3" style={{ padding: "5px" }}>
                <select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  style={{ height: "35px", borderRadius: "5px" }}
                >
                  <option value="">All Payout Status</option>
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>

              <div className="child col-md-3">
                <select
                  value={workshopSlotStatusFilter}
                  onChange={(e) => setWorkshopSlotStatusFilter(e.target.value)}
                  style={{ height: "35px", borderRadius: "5px" }}
                >
                  <option value="">All Workshop Slot Status</option>
                  <option value="Available">Available</option>
                  <option value="Reviewing">Reviewing</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            </div>

            <div className="table-responsive">
              {isLoading ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : Array.isArray(payout) && payout.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Workshop Details</th>
                      <th scope="col">Pass Details</th>
                      <th scope="col">Customer Details</th>
                      <th scope="col">Instructor Commission</th>
                      <th scope="col">Venue Details</th>
                      <th scope="col">Workshop Slot Status</th>
                      <th scope="col">Amount Received</th>
                      <th scope="col">Booking Date/Time</th>
                      <th scope="col">Instructor Payment Done</th>
                      <th scope="col">Payout Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInstPayout.map((payout, index) => (
                      <tr key={payout.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {payout.pass_shedule?.workshop?.name}
                          <br />
                          {payout.pass_shedule?.workshop_slot?.date}
                          <br />
                          {payout.pass_shedule?.workshop_slot?.startTime} - {payout.pass_shedule?.workshop_slot?.endTime}
                        </td>

                        <td>{payout.passes?.title || "-"}</td>

                        <td>{payout.pass_management?.users?.name}</td>
                        <td>{payout.instructor_pay || "0"} ({payout.pass_shedule?.instructor_commission || "0"}%)</td>

                        <td>{payout.venue?.name}</td>

                        <td>{payout.pass_shedule?.workshop_slot?.status || "No Status"}</td>
                        <td>{payout.amount}</td>

                        <td>{new Date(payout.created_at).toLocaleString()}</td>
                        <td>{payout.instructor_pay_done || "0"}</td>
                        <td>{payout.status || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredInstPayout.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default InstructorPassPayout;
