// import React, { useState, useEffect, useRef } from "react";
// import Header from "./Header.js";
// import Footer from "./Footer.js";
// import Select from "react-select";
// import { TailSpin } from "react-loader-spinner";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import "./Style.css";
// import Add_location from "../../Components/Admin_components/add_location.js";
// import Update_location from "../../Components/Admin_components/update_location.js";
// import Delete_location from "../../Components/Admin_components/delete_location.js";
// import Add_city from "../../Components/Admin_components/add_city.js";
// import Update_city from "../../Components/Admin_components/update_city.js";
// import Delete_city from "../../Components/Admin_components/delete_city.js";
// import "../../Api/apiUrls";
// import { getAdminLocationCity, getAdminLocationArea } from "../../Api/apiUrls";
// function PassPayout() {
//   const [modalAdd_locationIsOpen, setAdd_locationModalIsOpen] = useState("");
//   const [modalAdd_cityIsOpen, setAdd_cityModalIsOpen] = useState("");
//   const [modalUpdate_cityIsOpen, setUpdate_cityModalIsOpen] = useState(false);
//   const [modalAdd_areaModalIsOpen, setAdd_areaModalIsOpen] = useState("");
//   const [modalUpdate_locationIsOpen, setUpdate_locationModalIsOpen] =
//     useState(false);
//   const [modalDelete_cityIsOpen, setDelete_cityIsOpen] = useState(false);
//   const [modalDelete_locationIsOpen, setDelete_locationIsOpen] =
//     useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const [city, setCity] = useState([]);
//   const [location, setLocation] = useState([]);
//   const [selectedCityId, setSelectedCityId] = useState(null);
//   const [selectedAreaId, setSelectedAreaId] = useState(null);
//   const [selectedTab, setSelectedTab] = useState("city");

//   const [selectedCityName, setSelectedCityName] = useState(null);
//   const [selectedCityStatus, setSelectedCityStatus] = useState(null);
//   const [selectedLocationName, setSelectedLocationName] = useState(null);
//   const [selectedLocationStatus, setSelectedLocationStatus] = useState(null);
//   const [selectedLocationCity, setSelectedLocationCity] = useState(null);
//   const [selectedLocationId, setSelectedLocationId] = useState(null);
//   const openAddLocation = (tab) => {
//     setSelectedTab(tab);
//   };
//   const openAddcity = (tab) => {
//     setSelectedTab(tab);
//   };
//   const openAddarea = (tab) => {
//     setSelectedTab(tab);
//   };

//   const openDeleteLocation = () => { };
//   const Status = [
//     { value: "Active", label: "Active" },
//     { value: "Inactive", label: "Inactive" },
//   ];
//   const openAdd_location = (
//     locationName,
//     locationStatus,
//     locationCity,
//     locationId
//   ) => {
//     closeAdd_location();
//     setAdd_locationModalIsOpen(true);
//   };

//   const closeAdd_location = () => {
//     setAdd_locationModalIsOpen(false);
//   };
//   const openAdd_city = () => {
//     closeAdd_city();
//     setAdd_cityModalIsOpen(true);
//   };

//   const closeAdd_city = () => {
//     setAdd_cityModalIsOpen(false);
//   };
//   const openAdd_area = () => {
//     closeAdd_area();
//     setAdd_areaModalIsOpen(true);
//   };

//   const closeAdd_area = () => {
//     setAdd_areaModalIsOpen(false);
//   };
//   const openUpdate_location = (
//     locationId,
//     locationName,

//     selectedCity,
//     locationStatus
//   ) => {
//     setSelectedLocationId(locationId);
//     setSelectedLocationName(locationName);
//     setSelectedLocationCity(selectedCity);
//     setSelectedLocationStatus(locationStatus);

//     closeUpdate_location();
//     setUpdate_locationModalIsOpen(true);
//   };

//   const closeUpdate_location = () => {
//     setUpdate_locationModalIsOpen(false);
//   };
//   const handleTypeChange = (selectedOption) => {
//     setSelectedLocationStatus(selectedOption.value);
//   };
//   const openUpdate_city = (cityId, cityName, cityStatus) => {
//     setSelectedCityId(cityId);
//     setSelectedCityName(cityName);
//     setSelectedCityStatus(cityStatus);
//     console.log(cityStatus);
//     closeUpdate_city();
//     setUpdate_cityModalIsOpen(true);
//   };

//   const closeUpdate_city = () => {
//     setUpdate_cityModalIsOpen(false);
//   };
//   const openDelete_location = (locationId) => {
//     setSelectedAreaId(locationId);
//     closeDelete_location();
//     setDelete_locationIsOpen(true);
//   };

//   const closeDelete_location = () => {
//     setDelete_locationIsOpen(null);
//   };
//   const openDelete_city = (cityId) => {
//     setSelectedCityId(cityId);
//     closeDelete_city();
//     setDelete_cityIsOpen(true);
//   };

//   const closeDelete_city = () => {
//     setDelete_cityIsOpen(null);
//   };
//   useEffect(() => {
//     fetch(getAdminLocationCity)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         const city = data.data.LocationCity;
//         setCity(city);
//         console.log(city);
//       })
//       .catch((error) => console.error("Error fetching data:", error))
//       .finally(() => {
//         setIsLoading(false);
//       });
//   }, []);
//   useEffect(() => {
//     fetch(getAdminLocationArea)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         const area = data.data.LocationArea;
//         setLocation(area);
//         console.log(area);
//       })
//       .catch((error) => console.error("Error fetching data:", error))
//       .finally(() => {
//         setIsLoading(false);
//       });
//   }, []);

//   const [searchQuery, setSearchQuery] = useState('');
//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const filteredCity = city.filter((cityItem) => {
//     return cityItem.name.toLowerCase().includes(searchQuery.toLowerCase());
//   });

//   // Filtered data for area table
//   const filteredLocation = location.filter((locationItem) => {
//     return (
//       locationItem.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       locationItem.city.name.toLowerCase().includes(searchQuery.toLowerCase())
//     );
//   });

//   return (
//     <div>
//       <Header></Header>
//       {modalAdd_locationIsOpen && <Add_location onClose={closeAdd_location} />}
//       {modalAdd_cityIsOpen && <Add_city onClose={closeAdd_city} />}

//       {modalDelete_cityIsOpen && (
//         <Delete_city cityId={selectedCityId} onClose={closeDelete_city} />
//       )}
//       <main id="main" class="main">
//         <div class="pagetitle">
//           <h1>Pass PayOut</h1>
//           {/* <nav>
//             <ol class="breadcrumb">
//               <li class="breadcrumb-item">
//                 <a href="/categories">Home</a>
//               </li>
//               <li class="breadcrumb-item active">Location</li>
//             </ol>
//           </nav> */}
//         </div>
//         <section class="section">
//           <div class="card p-2">
//             <div class="parent col-md-12 row mt-2">
//               <div class="col-md-6">
//                 <div class="child">
//                   <input
//                     id="myInput"
//                     type="text"
//                     style={{
//                       borderRadius: "5px",
//                       height: "35px",
//                       padding: "10px",
//                       border: "1px solid lightgrey ",
//                     }}
//                     onChange={handleSearchChange}
//                     value={searchQuery}
//                     placeholder="Search..."
//                   />
//                 </div>
//               </div>

//               {/* <div className="col-md-6" style={{ textAlign: "right" }}>
//                 <div className="child">
//                   <a>
//                     {" "}
//                     {selectedTab === "city" && (
//                       <button
//                         id="btnNewUser"
//                         type="button"
//                         data-toggle="modal"
//                         data-target="#modalAdd"
//                         className=" btn-round"
//                         onClick={openAdd_city}
//                       // onClick={(openAdd_city) =>
//                       //   openAdd_location("openAdd_city")
//                       // }
//                       >
//                         <i className="bi bi-person-add"></i> Add City
//                       </button>
//                     )}
//                     {selectedTab === "area" && (
//                       <button
//                         id="btnNewUser"
//                         type="button"
//                         data-toggle="modal"
//                         data-target="#modalAdd"
//                         className=" btn-round"
//                         onClick={(openAdd_area) =>
//                           openAdd_location("openAdd_area")
//                         }
//                       >
//                         <i className="bi bi-person-add"></i> Add Area
//                       </button>
//                     )}
//                   </a>
//                 </div>
//               </div> */}
//             </div>
//             <div
//               style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
//             >
//               {/* <TailSpin color="black" width={50} /> */}
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <ul className="nav nav-tabs">
//                 <li
//                   className={`nav-item ${selectedTab === "city" ? "active" : ""
//                     }`}
//                 >
//                   <a
//                     className="nav-link"
//                     aria-current="page"
//                     href="#"
//                     onClick={() => openAddLocation("city")}
//                   >
//                     Instructor
//                   </a>
//                 </li>
//                 <li
//                   className={`nav-item ${selectedTab === "area" ? "active" : ""
//                     }`}
//                 >
//                   <a
//                     className="nav-link"
//                     href="#"
//                     onClick={() => openAddLocation("area")}
//                   >
//                     Brand Respresentative
//                   </a>
//                 </li>
//               </ul>
//             </div>

//             {selectedTab === "city" && (
//               <>
//                 {isLoading == true ? (
//                   <div
//                     style={{
//                       marginTop: "50px",
//                       display: "flex",
//                       justifyContent: "50%",
//                     }}
//                   >
//                     <TailSpin color="black" width={50} />
//                   </div>
//                 ) : (
//                   <table
//                     id="city"
//                     className="table table-striped"
//                     style={{ marginTop: "20px" }}
//                   >
//                     <thead>
//                       <tr>
//                         <th scope="col">Sr.No</th>
//                         <th scope="col">Instructor Name</th>
//                         <th scope="col">Workshop</th>
//                         <th scope="col">Payment</th>
//                         <th scope="col">Status</th>
//                         <th scope="col">Created On</th>
//                         <th scope="col">Action</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {filteredCity.map((city, index) => (
//                         <tr key={city.id}>
//                           <th scope="row">{index + 1}</th>
//                           <td>{city.name || "-"}</td>
//                           <td>{city.status || "-"}</td>

//                           <td>
//                             <div className="social-links mt-2">
//                               <a
//                                 style={{ cursor: "pointer" }}
//                                 onClick={() =>
//                                   openUpdate_city(
//                                     city.id,
//                                     city.name,

//                                     { value: city.id, label: city.status }
//                                   )
//                                 }
//                               >
//                                 <i class="bi bi-pencil"></i>
//                               </a>
//                               &nbsp;&nbsp;
//                               <a
//                                 style={{ cursor: "pointer" }}
//                                 onClick={() => openDelete_city(city.id)}
//                               >
//                                 {" "}
//                                 <i class="bi bi-trash3"></i>
//                               </a>
//                               &nbsp;
//                             </div>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 )}
//                 <div>
//                   {" "}
//                   <p
//                     style={{
//                       backgroundColor: "#000",
//                       color: "white",
//                       padding: "10px",
//                     }}
//                   >
//                     {filteredCity.length} Total
//                   </p>
//                 </div>
//               </>
//             )}

//             {selectedTab === "area" && (
//               <>
//                 {isLoading == true ? (
//                   <div
//                     style={{
//                       marginTop: "50px",
//                       display: "flex",
//                       justifyContent: "50%",
//                     }}
//                   >
//                     <TailSpin color="black" width={50} />
//                   </div>
//                 ) : (
//                   <table
//                     id="city"
//                     className="table table-striped"
//                     style={{ marginTop: "20px" }}
//                   >
//                     <thead>
//                       <tr>
//                         <th scope="col">Sr.No</th>
//                         <th scope="col">Brand Representative Name</th>
//                         <th scope="col">Workshop</th>
//                         <th scope="col">Payment</th>
//                         <th scope="col">Status</th>
//                         <th scope="col">Created On</th>
//                         <th scope="col">Action</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {filteredLocation.map((location, index) => (
//                         <tr key={location.id}>
//                           <th scope="row">{index + 1}</th>
//                           <td>
//                             {location.name || "-"}
//                           </td>
//                           <td>
//                             {location.city.name || "-"}
//                           </td>
//                           <td>
//                             {location.status || "-"}
//                           </td>

//                           <td>
//                             <div className="social-links mt-2">
//                               <a
//                                 style={{ cursor: "pointer" }}
//                                 onClick={() =>
//                                   openUpdate_location(
//                                     location.id,
//                                     location.name,

//                                     {
//                                       value: location.city.id,
//                                       label: location.city.name,
//                                     },

//                                     {
//                                       value: location.id,
//                                       label: location.status,
//                                     }
//                                   )
//                                 }
//                               >
//                                 <i class="bi bi-pencil"></i>
//                               </a>
//                               &nbsp;&nbsp;
//                               <a
//                                 style={{ cursor: "pointer" }}
//                                 onClick={() => openDelete_location(location.id)}
//                               >
//                                 {" "}
//                                 <i class="bi bi-trash3"></i>
//                               </a>
//                               &nbsp;
//                             </div>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 )}
//                 <div>
//                   {" "}
//                   <p
//                     style={{
//                       backgroundColor: "#000",
//                       color: "white",
//                       padding: "10px",
//                     }}
//                   >
//                     {filteredLocation.length} Total
//                   </p>
//                 </div>
//               </>
//             )}

//             {modalUpdate_cityIsOpen && (
//               <Update_city
//                 cityId={selectedCityId}
//                 cityName={selectedCityName}
//                 cityStatus={selectedCityStatus}
//                 onClose={closeUpdate_city}
//               />
//             )}

//             {modalUpdate_locationIsOpen && (
//               <Update_location
//                 locationId={selectedLocationId}
//                 locationName={selectedLocationName}
//                 selectedCity={selectedLocationCity}
//                 locationStatus={selectedLocationStatus}
//                 onClose={closeUpdate_city}
//               />
//             )}
//             {modalDelete_locationIsOpen && (
//               <Delete_location
//                 locationId={selectedAreaId}
//                 onClose={closeDelete_location}
//               />
//             )}

//           </div>
//         </section>
//       </main>
//       <Footer></Footer>
//     </div>
//   );
// }

// export default PassPayout;

import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
// import "./pass_pay_out.css"; // Importing the separated CSS
import Update_PassPayout from "../../Components/Admin_components/update_pass_payout.js";
import { getAdminPassPayout } from "../../Api/apiUrls";
import BankDetailsPopup from "../../Components/Admin_components/bankDetailsPopup.js"; // Import the component

function PassPayout() {
  const [payout, setPayout] = useState([]); // Initialized as an empty array
  const [isLoading, setIsLoading] = useState(true);
  const [
    modalUpdate_PassPayoutIsOpen,
    setUpdate_PassPayoutModalIsOpen,
  ] = useState(false);
  const [selectedpassPayoutId, setselectedpassPayoutId] = useState(null);
  const [selectedpassPayoutStatus, setselectedpassPayoutStatus] = useState(null);
  const [selectedinstructorPayment, setselectedinstructorPayment] = useState(null);
  const [selectedvenueProviderPayment, setselectedvenueProviderPayment] = useState(null);
  const [selectedbrandRepPayment, setselectedbrandRepPayment] = useState(null);
  const [selectedinstructorPaymentDone, setselectedinstructorPaymentDone] = useState(null);
  const [selectedvenueProviderPaymentDone, setselectedvenueProviderPaymentDone] = useState(null);
  const [selectedbrandRepPaymentDone, setselectedbrandRepPaymentDone] = useState(null);
  const [selectedremark, setselectedremark] = useState(null);

  const openUpdate_passPayout = (
    passPayoutId,
    passPayoutStatus,
    instructorPayment,
    venueProviderPayment,
    brandRepPayment,
    instructorPaymentDone,
    venueProviderPaymentDone,
    brandRepPaymentDone,
    remark
  ) => {
    setselectedpassPayoutId(passPayoutId);
    setselectedpassPayoutStatus(passPayoutStatus);
    setselectedinstructorPayment(instructorPayment);
    setselectedvenueProviderPayment(venueProviderPayment);
    setselectedbrandRepPayment(brandRepPayment);
    setselectedinstructorPaymentDone(instructorPaymentDone);
    setselectedvenueProviderPaymentDone(venueProviderPaymentDone);
    setselectedbrandRepPaymentDone(brandRepPaymentDone);
    setselectedremark(remark);
    closeUpdate_passPayout();
    setUpdate_PassPayoutModalIsOpen(true);
  };
  const [isBankDetailsPopupOpen, setIsBankDetailsPopupOpen] = useState(false);
  const [selectedBankDetails, setSelectedBankDetails] = useState({});

  const openBankDetailsPopup = (bankDetails) => {
    setSelectedBankDetails(bankDetails);
    setIsBankDetailsPopupOpen(true);
  };

  const closeBankDetailsPopup = () => {
    setIsBankDetailsPopupOpen(false);
  };
  const closeUpdate_passPayout = () => {
    setUpdate_PassPayoutModalIsOpen(false);
  };

  useEffect(() => {
    fetch(getAdminPassPayout)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const payout = data.data?.PassManagementScheduleBooked || [];
        setPayout(payout);
        console.log("Payout fetched:", payout); // Debugging log
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const formatDateTime = (dateTime) => {
    const formattedDate = dateTime.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    const formattedTime = dateTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    return `${formattedDate} ${formattedTime}`;
  };

  const filteredInstPayout = Array.isArray(payout)
    ? payout.filter((payout) => {
        const values = Object.values(payout).map((value) =>
          typeof value === "string" ? value.toLowerCase() : ""
        );
        return values.some((value) =>
          value.includes(searchQuery.toLowerCase())
        );
      })
    : [];
    
  return (
    <div>
      <Header />
      {modalUpdate_PassPayoutIsOpen && (
        <Update_PassPayout
          onClose={closeUpdate_passPayout}
          passPayoutId={selectedpassPayoutId}
          passPayoutStatus={selectedpassPayoutStatus}
          instructorPayment={selectedinstructorPayment}
          venueProviderPayment={selectedvenueProviderPayment}
          brandRepPayment={selectedbrandRepPayment}
          instructorPaymentDone={selectedinstructorPaymentDone}
          venueProviderPaymentDone={selectedvenueProviderPaymentDone}
          brandRepPaymentDone={selectedbrandRepPaymentDone}
          remark={selectedremark}
        />
      )}
      {isBankDetailsPopupOpen && (
        <BankDetailsPopup
          isOpen={isBankDetailsPopupOpen}
          onClose={closeBankDetailsPopup}
          bankDetails={selectedBankDetails}
        />
      )}
      
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Pass PayOut</h1>
        </div>
        <section className="section">
          <div className="card p-2">
            <div className="parent col-md-12 row mt-2">
              <div className="col-md-6">
                <div className="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              {isLoading ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : Array.isArray(payout) && payout.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Workshop Details</th>
                      <th scope="col">Pass Details</th>
                      <th scope="col">Customer Details</th>
                      <th scope="col">Transaction details</th>
                      <th scope="col">Instructor Details</th>
                      <th scope="col">Instructor Commission</th>
                      <th scope="col">Brand Representative Details</th>
                      <th scope="col">Brand Representative Commission</th>
                      <th scope="col">Venue Details</th>
                      <th scope="col">Venue Provider Details</th>
                      <th scope="col">Venue Provider Commission</th>
                      <th scope="col">Workshop Slot Status</th>
                      <th scope="col">Amount Received</th>
                      <th scope="col">Booking Date/Time</th>
                      <th scope="col">Instructor Payment Done</th>
                      <th scope="col">Venue Provider Payment Done</th>
                      <th scope="col">Brand Representative Payment Done</th>
                      <th scope="col">Payout Status</th>
                      <th scope="col">Remark</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInstPayout.map((payout, index) => (
                      <tr key={payout.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {payout.pass_shedule?.workshop?.name}
                          <br />
                          {payout.pass_shedule?.workshop_slot?.date}
                          <br />
                          {payout.pass_shedule?.workshop_slot?.startTime} - {payout.pass_shedule?.workshop_slot?.endTime}
                        </td>

                        <td>
                          {payout.passes?.title || "-"}                  
                          <br />
                          {payout.passes?.duration_type || "-"}
                        </td>
                        
                        <td>
                          {payout.pass_management?.users?.name}
                          <br />
                          {payout.pass_management?.users?.email}
                          <br />
                          {payout.pass_management?.users?.mobileNumber}
                        </td>
                        <td>
                          <b>OrderId:</b> {payout.pass_management?.transaction?.order_id || "-"}
                          <br />
                          <b>PayId:</b> {payout.pass_management?.transaction?.payment_id || "-"}
                        </td>
                        <td>
                          {payout.instructor?.name || "-"}
                          <br />
                          {payout.instructor?.mobileNumber || "-"}
                          <br />
                          {payout.instructor?.email || "-"}
                          <br />
                          <i
                            className="bi bi-bank"
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() =>
                              openBankDetailsPopup({
                                bank_name: payout.instructor?.bank_name,
                                bank_account_number: payout.instructor?.bank_account_number,
                                bank_ifsc_code: payout.instructor?.bank_ifsc_code,
                                bank_account_type: payout.instructor?.bank_account_type,
                              })
                            }
                          >
                            View Bank Details
                          </i>
                        </td>
                        <td>{payout.instructor_pay || "0"} ({payout.pass_shedule?.instructor_commission || "0"}%)</td>
                        <td>
                          {payout.brand_representative?.name || "-"}
                          <br />
                          {payout.brand_representative?.code || "-"}
                          <br />
                          {payout.brand_representative?.mobileNumber || "-"}
                          <br />
                          {payout.brand_representative?.email || "-"}
                          <br />
                          <i
                            className="bi bi-bank"
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() =>
                              openBankDetailsPopup({
                                bank_name: payout.brand_representative?.bank_name,
                                bank_account_number: payout.brand_representative?.bank_account_number,
                                bank_ifsc_code: payout.brand_representative?.bank_ifsc_code,
                                bank_account_type: payout.brand_representative?.bank_account_type,
                              })
                            }
                          >
                            View Bank Details
                          </i>
                        </td>
                        <td>{payout.brand_representative_pay || "0"} ({payout.pass_management?.br_commission || "0"}%)</td>
                        
                        <td>{payout.venue?.name}</td>
                        
                        <td>
                          {payout.venue?.venue_owner?.name || "-"}
                          <br />
                          {payout.venue?.venue_owner?.mobileNumber || "-"}
                          <br />
                          {payout.venue?.venue_owner?.email || "-"}
                          <br />
                          <i
                            className="bi bi-bank"
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() =>
                              openBankDetailsPopup({
                                bank_name: payout.venue?.venue_owner?.bank_name,
                                bank_account_number: payout.venue?.venue_owner?.bank_account_number,
                                bank_ifsc_code: payout.venue?.venue_owner?.bank_ifsc_code,
                                bank_account_type: payout.venue?.venue_owner?.bank_account_type,
                              })
                            }
                          >
                            View Bank Details
                          </i>
                        </td>
                        
                        <td>{payout.vp_pay || "0"} ({payout.pass_shedule?.vp_commission || "0"}%)</td>
                        <td>{payout.pass_shedule?.workshop_slot?.status || "No Status"}</td>
                        <td>{payout.amount}</td>
                        
                        <td>{formatDateTime(new Date(payout.created_at))}</td>
                        <td>{payout.instructor_pay_done || "0"}</td>
                        <td>{payout.vp_pay_done || "0"}</td>
                        <td>{payout.br_pay_done || "0"}</td>
                        <td>{payout.status || "-"}</td>
                        <td>{payout.remark || "-"}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openUpdate_passPayout(
                                payout.id,
                                payout.status,
                                payout.instructor_pay,
                                payout.vp_pay,
                                payout.brand_representative_pay,
                                payout.instructor_pay_done,
                                payout.vp_pay_done,
                                payout.br_pay_done,
                                payout.remark
                              )
                            }
                          >
                            <i className="bi bi-pencil"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            <p
              style={{
                backgroundColor: "#595857",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredInstPayout.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default PassPayout;
