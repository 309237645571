import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import { getAdminPassManagement } from "../../Api/apiUrls";
import ViewPassScheduleBooked from "../Admin/viewPassScheduleBooked.js";

function CustomerPassManagement() {
  const [modalUpdate_packagesIsOpen, setUpdate_packagesModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [passManagement, setPassManagement] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPassManagement, setFilteredPassManagement] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({ value: "All", label: "All" });

  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Expired", label: "Expired" },
  ];

  useEffect(() => {
    fetch(getAdminPassManagement)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const passManagement = data.data.PassManagement;
        setPassManagement(passManagement);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const newFilteredPassManagement = passManagement.filter((item) => {
      const searchCondition = Object.values(item).some((value) => {
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some((subValue) => {
            if (typeof subValue === "string") {
              return subValue.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
          });
        } else if (typeof value === "string") {
          return value.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return false;
      });

      const statusCondition =
        selectedStatus.value === "All" || item.status === selectedStatus.value;

      return searchCondition && statusCondition;
    });

    setFilteredPassManagement(newFilteredPassManagement);
  }, [searchQuery, selectedStatus, passManagement]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${datePart} - ${timePart}`;
  };

  const handleClick = (id) => {
    setUpdate_packagesModalIsOpen(true);
  };

  return (
    <div>
      <Header />
      {modalUpdate_packagesIsOpen && <ViewPassScheduleBooked onClose={() => setUpdate_packagesModalIsOpen(false)} />}
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Pass Management</h1>
        </div>
        <section className="section">
          <div className="card p-2">
            <div className="parent col-md-12 row mt-2">
              <div className="row">
                <div className="child col-md-2" style={{ padding: "5px" }}>
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "37px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width: "100%",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search by Pass, Order ID, or Brand Representative..."
                  />
                </div>
                <div className="col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={status}
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    placeholder="Select Status"
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : passManagement.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.no</th>
                      <th scope="col">Pass</th>
                      <th scope="col">Details</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Price</th>
                      <th scope="col">Brand Representative</th>
                      <th scope="col">Start Month</th>
                      <th scope="col">End Month</th>
                      <th scope="col">Transaction Status</th>
                      <th scope="col">Purchased At</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredPassManagement.map((item, index) => (
                      <tr key={item.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{item?.passes?.title || "---"}</td>
                        <td style={{ textAlign: "center" }}>
                          <a onClick={() => handleClick(item.id)}>
                            <i className="bi bi-eye-fill"></i>
                          </a>
                        </td>
                        <td>{item?.transaction?.order_id || "---"}</td>
                        <td>{item?.price || "---"}</td>
                        <td>
                          {item?.brand_representative?.name || "---"}
                          <br />
                          {item?.brand_representative?.code || "---"}
                        </td>
                        <td>{item?.start_month || "---"}</td>
                        <td>{item?.end_month || "---"}</td>
                        <td>{item?.transaction?.transaction_status || "---"}</td>
                        <td>{formatCreatedAt(item?.created_at || "---")}</td>
                        <td>{item?.status || "---"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}>No data available</h3>
              )}
            </div>
          </div>
          <div>
            <p style={{ backgroundColor: "#000", color: "white", padding: "10px" }}>
              {filteredPassManagement.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default CustomerPassManagement;
