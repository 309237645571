

import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
// import "./pass_pay_out.css"; // Importing the separated CSS
import Update_PassPayout from "../../Components/Admin_components/update_pass_payout.js";
import { getBrPassPayout } from "../../Api/apiUrls";
import BankDetailsPopup from "../../Components/Admin_components/bankDetailsPopup.js"; // Import the component

function BrandRepresentativePassPayout() {
  const [payout, setPayout] = useState([]); // Initialized as an empty array
  const [isLoading, setIsLoading] = useState(true);
  const [
    modalUpdate_PassPayoutIsOpen,
    setUpdate_PassPayoutModalIsOpen,
  ] = useState(false);
  const [selectedpassPayoutId, setselectedpassPayoutId] = useState(null);
  const [selectedpassPayoutStatus, setselectedpassPayoutStatus] = useState(null);
  const [selectedinstructorPayment, setselectedinstructorPayment] = useState(null);
  const [selectedvenueProviderPayment, setselectedvenueProviderPayment] = useState(null);
  const [selectedbrandRepPayment, setselectedbrandRepPayment] = useState(null);
  const [selectedinstructorPaymentDone, setselectedinstructorPaymentDone] = useState(null);
  const [selectedvenueProviderPaymentDone, setselectedvenueProviderPaymentDone] = useState(null);
  const [selectedbrandRepPaymentDone, setselectedbrandRepPaymentDone] = useState(null);
  const [selectedremark, setselectedremark] = useState(null);

  const openUpdate_passPayout = (
    passPayoutId,
    passPayoutStatus,
    instructorPayment,
    venueProviderPayment,
    brandRepPayment,
    instructorPaymentDone,
    venueProviderPaymentDone,
    brandRepPaymentDone,
    remark
  ) => {
    setselectedpassPayoutId(passPayoutId);
    setselectedpassPayoutStatus(passPayoutStatus);
    setselectedinstructorPayment(instructorPayment);
    setselectedvenueProviderPayment(venueProviderPayment);
    setselectedbrandRepPayment(brandRepPayment);
    setselectedinstructorPaymentDone(instructorPaymentDone);
    setselectedvenueProviderPaymentDone(venueProviderPaymentDone);
    setselectedbrandRepPaymentDone(brandRepPaymentDone);
    setselectedremark(remark);
    closeUpdate_passPayout();
    setUpdate_PassPayoutModalIsOpen(true);
  };
  const [isBankDetailsPopupOpen, setIsBankDetailsPopupOpen] = useState(false);
  const [selectedBankDetails, setSelectedBankDetails] = useState({});

  const openBankDetailsPopup = (bankDetails) => {
    setSelectedBankDetails(bankDetails);
    setIsBankDetailsPopupOpen(true);
  };

  const closeBankDetailsPopup = () => {
    setIsBankDetailsPopupOpen(false);
  };
  const closeUpdate_passPayout = () => {
    setUpdate_PassPayoutModalIsOpen(false);
  };

  const user_id = localStorage.getItem('user_id');
  useEffect(() => {
    fetch(`${getBrPassPayout}${user_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const payout = data.data?.PassManagementScheduleBooked || [];
        setPayout(payout);
        console.log("Payout fetched:", payout); // Debugging log
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const formatDateTime = (dateTime) => {
    const formattedDate = dateTime.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    const formattedTime = dateTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    return `${formattedDate} ${formattedTime}`;
  };

  const filteredInstPayout = Array.isArray(payout)
    ? payout.filter((payout) => {
        const values = Object.values(payout).map((value) =>
          typeof value === "string" ? value.toLowerCase() : ""
        );
        return values.some((value) =>
          value.includes(searchQuery.toLowerCase())
        );
      })
    : [];
    
  return (
    <div>
      <Header />
      {modalUpdate_PassPayoutIsOpen && (
        <Update_PassPayout
          onClose={closeUpdate_passPayout}
          passPayoutId={selectedpassPayoutId}
          passPayoutStatus={selectedpassPayoutStatus}
          instructorPayment={selectedinstructorPayment}
          venueProviderPayment={selectedvenueProviderPayment}
          brandRepPayment={selectedbrandRepPayment}
          instructorPaymentDone={selectedinstructorPaymentDone}
          venueProviderPaymentDone={selectedvenueProviderPaymentDone}
          brandRepPaymentDone={selectedbrandRepPaymentDone}
          remark={selectedremark}
        />
      )}
      {isBankDetailsPopupOpen && (
        <BankDetailsPopup
          isOpen={isBankDetailsPopupOpen}
          onClose={closeBankDetailsPopup}
          bankDetails={selectedBankDetails}
        />
      )}
      
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Pass PayOut</h1>
        </div>
        <section className="section">
          <div className="card p-2">
            <div className="parent col-md-12 row mt-2">
              <div className="col-md-6">
                <div className="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              {isLoading ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : Array.isArray(payout) && payout.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Workshop Details</th>
                      <th scope="col">Pass Details</th>
                      <th scope="col">Customer Details</th>
                      <th scope="col">Brand Representative Commission</th>
                      <th scope="col">Workshop Slot Status</th>
                      <th scope="col">Booking Date/Time</th>
                      <th scope="col">Brand Representative Payment Done</th>
                      <th scope="col">Payout Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInstPayout.map((payout, index) => (
                      <tr key={payout.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {payout.pass_shedule?.workshop?.name}
                          <br />
                          {payout.pass_shedule?.workshop_slot?.date}
                          <br />
                          {payout.pass_shedule?.workshop_slot?.startTime} - {payout.pass_shedule?.workshop_slot?.endTime}
                        </td>

                        <td>
                          {payout.passes?.title || "-"}                  
                          <br />
                          {payout.passes?.duration_type || "-"}
                        </td>
                        
                        <td>
                          {payout.pass_management?.users?.name}
                        </td>
                        <td>{payout.brand_representative_pay || "0"} ({payout.pass_management?.br_commission || "0"}%)</td>
                        
                        <td>{payout.pass_shedule?.workshop_slot?.status || "No Status"}</td>
                        
                        <td>{formatDateTime(new Date(payout.created_at))}</td>
                        <td>{payout.br_pay_done || "0"}</td>
                        <td>{payout.status || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            <p
              style={{
                backgroundColor: "#595857",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredInstPayout.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default BrandRepresentativePassPayout;
