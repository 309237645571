import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "../../Components/Admin_components/update_packages.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { getPassScheduleBooked, UpdateAdminPackages } from "../../Api/apiUrls";

import { TailSpin } from "react-loader-spinner";
function ViewPassScheduleBooked({
    id,
  onClose,

}) {
  const [modalUpdate_packagesIsOpen, setUpdate_packagesModalIsOpen] =
    useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [schedule, setSchedule] = useState(false);
 
  useEffect(() => {
    fetch(`${getPassScheduleBooked}?pass_management_id=${id}`)
        .then((response) => {
          console.log(response)
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then((data) => {
            const schedule = data.data.PassSchedule;
            setSchedule(schedule);

        })
        .catch((error) => console.error("Error fetching data:", error))
        .finally(() => {
            setIsLoading(false);
        });
}, [id]);

const formatCreatedAt = (createdAt) => {
  const createdAtDate = new Date(createdAt);
  // Format the date part
  const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
  });
  return `${datePart}`;
};

  const closeUpdate_packages = () => {
    setUpdate_packagesModalIsOpen(false);
  };
 

  function reloadPage() {
    window.location.reload();
  }
  
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

 
  return (
    <div>
      {modalUpdate_packagesIsOpen && (
        <ViewPassScheduleBooked onClose={closeUpdate_packages} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Pass Scheduled Booked</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  // reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
             <div class="table-responsive">
                            {isLoading === true ? (
                                <div
                                    style={{
                                        marginTop: "50px",
                                        display: "flex",
                                        marginLeft: "50%",
                                    }}
                                >
                                    <TailSpin color="black" width={50} />
                                </div>
                            ) : schedule.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.No</th>
                                            <th scope="col">Venue</th>
                                            <th scope="col">Year</th>
                                            <th scope="col">Month</th>
                                            <th scope="col">Week</th>
                                            <th scope="col">Workshop</th>
                                            <th scope="col">Workshop Slot </th>
                                        </tr>
                                    </thead>
                                    <tbody id="tableBody">
                                        {schedule.map((schedule, index) => (
                                            <tr key={schedule.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{schedule?.venue?.name || "---"}</td>
                                                <td>{schedule.year || "---"}</td>
                                                <td>
                                                    {schedule.month
                                                        ? new Date(`1970-${schedule.month}-01`).toLocaleString('en-US', { month: 'long' })
                                                        : "---"}
                                                </td>
                                                <td>
                                                    {schedule.week
                                                        ? `${parseInt(schedule.week)}${["th", "st", "nd", "rd"][
                                                        (parseInt(schedule.week) % 10 > 3 || parseInt(schedule.week) % 10 === 0 || [11, 12, 13].includes(parseInt(schedule.week))) ? 0 : parseInt(schedule.week) % 10
                                                        ]} week`
                                                        : "---"}
                                                </td>

                                                <td>{schedule.workshop.name || "---"}</td>
                                                <td>{formatCreatedAt(schedule.workshop_slot.date || "---")}
                                                    <br></br>
                                                    {schedule.workshop_slot.startTime
                                                        ? new Date(`1970-01-01T${schedule.workshop_slot.startTime}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                                                        : "---"}
                                                    -
                                                    {schedule.workshop_slot.endTime
                                                        ? new Date(`1970-01-01T${schedule.workshop_slot.endTime}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                                                        : "---"}


                                                </td>
                                               
                                            </tr>
                                        ))}

                                    </tbody>

                                </table>

                            ) : (
                                <h3 style={{ textAlign: "center" }}> No data available </h3>
                            )}


                        </div>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  // reloadPage();
                }}
              >
                Close
              </button>

              {/* <div>
                <ThreeDots color="#000" height={50} width={50} />
              </div> */}

             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewPassScheduleBooked;
