import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_pass_payout.css";

function BankDetailsPopup({
  isOpen,
  onClose,
  bankDetails,
  onUpdateBankDetails
}) {
  const [newBankDetails, setNewBankDetails] = useState({
    bank_name: bankDetails.bank_name || "",
    bank_account_number: bankDetails.bank_account_number || "",
    bank_ifsc_code: bankDetails.bank_ifsc_code || "",
    bank_account_type: bankDetails.bank_account_type || "",
  });

  const [isUpdating, setIsUpdating] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBankDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    setIsUpdating(true);
    // Call onUpdateBankDetails with new bank details
    onUpdateBankDetails(newBankDetails)
      .then(() => {
        alert("Bank details updated successfully!");
        onClose();
      })
      .catch((error) => {
        console.error("Error updating bank details:", error);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  if (!isOpen) return null;

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="modal-content" style={{ width: "100%",
              // height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",}}>
          <div className="modal-header" style={{ width: "100%"}}>
            <h5 className="modal-title" >
              <b>Bank Details</b>
            </h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => onClose(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ padding: "2%" }}>
            <form>
              <div className="form-group">
                <label>Bank Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="bank_name"
                  value={newBankDetails.bank_name}
                  onChange={handleInputChange} readOnly
                />
              </div>
              <div className="form-group">
                <label>Account Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="bank_account_number"
                  value={newBankDetails.bank_account_number}
                  onChange={handleInputChange} readOnly
                />
              </div>
              <div className="form-group">
                <label>IFSC Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="bank_ifsc_code"
                  value={newBankDetails.bank_ifsc_code}
                  onChange={handleInputChange} readOnly
                />
              </div>
              <div className="form-group">
                <label>Account Type</label>
                <input
                  type="text"
                  className="form-control"
                  name="bank_account_type"
                  value={newBankDetails.bank_account_type}
                  onChange={handleInputChange} readOnly
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-danger" onClick={() => onClose(false)}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankDetailsPopup;
